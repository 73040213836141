/* eslint-disable react/jsx-no-literals */
import { h } from 'preact'

import { TITLES } from '../../config'
import BackgroundTitle from '../atom/BackgroundTitle'
import { SiteContentEntry } from '../molecule/SiteContent'
import CodeblockLoader from '../atom/Codeblock'

const Error = ({ error }) => (
  <SiteContentEntry textMargin>
    <BackgroundTitle>{TITLES.ERROR}</BackgroundTitle>

    <CodeblockLoader language="json">
      { JSON.stringify(error, null, 2) }
    </CodeblockLoader>
  </SiteContentEntry>
)

Error.Footer = false

export default Error
