import NotFound from '../../js/components/page/not-found'
import Error from '../../js/components/page/error'

function Page({ is404, error }) {
  return is404
    ? <NotFound />
    : <Error error={error} />
}

export default Page
